/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */

@use "./theme";

@use '@material/button/styles' as button-styles;
@use "@material/icon-button";
@use "@material/icon-button/styles" as icon-button-styles;
@use "@material/top-app-bar/mdc-top-app-bar";
@use "@material/circular-progress/mdc-circular-progress";
@use "@material/layout-grid/mdc-layout-grid";
@use "@material/card";
@use "@material/fab";
@use "@material/list";
@use "@material/list/mdc-list";
@use "@material/menu-surface/mdc-menu-surface";
@use "@material/menu/mdc-menu";
@use "@material/ripple";
@use "@material/floating-label/mdc-floating-label";
@use "@material/line-ripple/mdc-line-ripple";
@use "@material/notched-outline/mdc-notched-outline";
@use "@material/textfield";
@use "@material/radio/styles" as radio-styles;
@use "@material/form-field";

@include card.core-styles;
@include fab.core-styles;
@include list.deprecated-core-styles;
@include textfield.core-styles;
@include form-field.core-styles;

@import "@material/typography/mdc-typography";
@import "@material/elevation/mdc-elevation";

// Material icons
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

html, body {
    height: 100%
}

body {
    margin: 0
}

// Headers
h2 {
  @include mdc-typography(headline2);
}
h3 {
  @include mdc-typography(headline3);
}
h4 {
  @include mdc-typography(headline4);
}
h5 {
   @include mdc-typography(headline5);
}

main {
  margin: {
    top: 0;
    left: auto;
    bottom: 6rem;
    right: auto;
  }
}

header {
    .userPhoto {
        width: 36x;
        height: 36px;
        border-radius: 50%;
        border: 2px solid #fff;
    }

    .userMenuAnchorSurface {
      cursor: pointer;
      outline: none;
      @include ripple.surface;
      @include ripple.radius-unbounded;
      @include ripple.states(secondary);
    }
}

footer {
  color: #444;
  text-align: center;
  @include mdc-typography(caption);
}

.loading-container {
    text-align: center;
    @include mdc-typography(subtitle2);
    .mdc-circular-progress {
        display: block;
        margin: {
          top: 0;
          left: auto;
          bottom: 2em;
          right: auto;
        }
    }
    display: flex;
    justify-content: center;
}

.product-card {
    width: 350px;
    margin: {
      top: 0;
      left: auto;
      bottom: 2em;
      right: auto;
    }
    @include mdc-elevation(4);

    .product-card-content {
        padding: 16px;
        text-align: center;
    }
    .description {
      @include mdc-typography(body2);
    }
    .pricing {
      @include mdc-typography(subtitle1);
    }

    .product-card__media {
      background-image: url("../images/jesson-mata-I2Erl2cU_A4-unsplash.jpg");
      .mdc-card__media-content {
        h5 {
            text-align: center;
            margin-top: 16px;
            font-weight: bold;
        }
        @include theme.property(color, "primary");
      }
    }
}

.products {
    #subscribe {
        display: block;
        margin: {
          top: 0;
          left: auto;
          bottom: 2em;
          right: auto;
        }
    }
}

.payment-onboarding {
    .stripe-information {
        @include mdc-elevation(4);

        .stripe-information-content {
            padding: 16px 135px 16px 16px;
            text-align: left;
            background: url("../images/stripe.svg") right 25px center no-repeat;
            background-size: 80px auto;
            @include mdc-typography(body2);
        }

        margin: {
              bottom: 2em;
        }
    }

    .submit-zone {
        display: flex;
        justify-content: center;
    }
}

.test-card-notice {
    margin-bottom: 1em;
}

.terms-of-service, privacy-policy {
    margin: {
        left: 16px;
        right: 16px;
    }
    p {
      @include mdc-typography(body1);
    }
}
